@import "/src/app/styles/index";

.content {
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: space-between;

	width: 184px;

	z-index: 10;
	position: relative;

	@include mediaMax($screenMd) {
		grid-auto-flow: row;

		justify-items: center;

		width: 340px;
	}
}

.blockchains {
	width: 142px;
	height: 393px;

	background: rgba(217, 217, 217, 0.01);
	box-shadow: 0 0 54px rgba(79, 211.84, 241, 0.5) inset,
		0 0 54px rgba(79, 211.84, 241, 0.5);

	border-radius: 5px;
	border: 2px #4fd4f1 solid;

	display: grid;
	justify-content: center;
	justify-items: center;

	padding-top: 25px;
	padding-bottom: 25px;

	@include mediaMax($screenMd) {
		width: 340px;
		height: 78px;

		padding-top: 0;
		padding-bottom: 0;

		grid-auto-flow: column;
		align-items: center;
		column-gap: 13px;
	}
}

.iconOfBlockchain {
	transition: width 0.2s;

	width: 52px;

	cursor: pointer;

	&.active {
		width: 70px;
		border-radius: 50%;

		border: 1px #52dbfc solid;
	}

	@include mediaMax($screenMd) {
		width: 41px;

		&.active {
			width: 60px;
		}
	}
}

.title {
	rotate: -90deg;
	transform-origin: center;

	opacity: 0.8;

	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 500;
	text-align: center;

	width: 180px;

	@include mediaMax($screenMd) {
		rotate: 0deg;
	}
}

.wTitle {
	width: 20px;
	height: 393px;

	display: grid;
	align-items: center;
	justify-items: center;
	justify-content: center;

	@include mediaMax($screenMd) {
		width: 180px;
		height: 20px;

		margin-bottom: 16px;
	}
}
