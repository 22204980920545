@import "/src/app/styles/index";

.content {
	position: absolute;
	top: -230px;
	left: 0;

	overflow-y: scroll;
	height: 1000px;
	width: calc(100vw);

	overscroll-behavior: none;

	z-index: 4;

	display: grid;
	justify-content: center;
	justify-items: center;

	&::-webkit-scrollbar {
		display: none;
	}
}

.wContent {
	height: 1000px;

	display: grid;
	align-content: start;
	justify-content: center;
	justify-items: center;

	margin-top: 10px;

	position: relative;

	@include mediaMax($screenMd) {
		margin-top: 30px;

		width: 100vw;
		overflow: hidden;

		height: 750px;
	}
}

.wrap {
	height: calc(100vh + 10000px);
}

.arrow {
}

.hiddenBlock {
	position: absolute;
	top: 554px;

	transition: opacity 2s;

	opacity: 0;

	@include mediaMax($screenMd) {
		top: 460px;
	}
}

.title {
	text-align: center;
	color: white;
	font-size: 32px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 40px;
	letter-spacing: 1.6px;

	@include mediaMax($screenMd) {
		font-size: 24px;
		letter-spacing: 1.2px;
	}
}

.areaDesc {
	display: grid;
	align-content: start;
	row-gap: 8px;

	margin-top: 44px;

	@include mediaMax($screenMd) {
		margin-top: 30px;
	}
}

.unlockBlock {
	width: 433px;
	height: 80px;

	background: #111c26;
	border-radius: 12px;

	display: grid;
	row-gap: 16px;
	justify-items: center;
	align-content: center;

	@include mediaMax($screenMd) {
		width: 301px;
		height: 80px;

		row-gap: 8px;
	}
}

.titleBlock {
	text-align: center;
	color: #8f73ff;
	font-size: 16px;
	font-family: $mainFontFamily;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 20px;
}

.descBlock {
	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 500;

	span {
		font-weight: 800;

		cursor: pointer;
	}

	@include mediaMax($screenMd) {
		text-align: center;
	}
}

.lockBlock {
	display: grid;
	align-items: center;
	justify-items: center;

	width: 433px;
	height: 49px;

	background: #111c26;
	border-radius: 12px;

	img {
		filter: drop-shadow(0 0 20px #8f73ff) drop-shadow(0 0 6px #8f73ff);
	}

	@include mediaMax($screenMd) {
		width: 298px;
	}
}

.portal {
	width: 200px;
	rotate: 200deg;

	will-change: width;

	transition: rotate 0.2s;

	pointer-events: none;

	@include mediaMax($screenMd) {
		width: 170px;
	}
}
