@import "/src/app/styles/index";

.content {
	display: grid;
	justify-content: space-between;
	grid-auto-flow: column;

	padding-right: 40px;
	padding-left: 40px;
	margin-top: 28px;

	@include mediaMax($screenMd) {
		padding-right: 24px;
		padding-left: 10px;
		margin-top: 10px;
	}
}

.logo {
	width: 150px;
}

.wButton {
	@include mediaMax($screenMd) {
		display: none;
	}
}

.burgerMenu {
	display: none;
	@include mediaMax($screenMd) {
		display: block;

		margin-top: 10px;

		cursor: pointer;
	}
}
