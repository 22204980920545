@import "/src/app/styles/index";

.content {
	margin-top: 80px;
	display: grid;
	justify-items: center;
	align-content: start;
}

.block {
	width: 1361px;
	max-width: 98vw;
	height: 384px;

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	background: #111c26;
	border-radius: 11px;

	position: relative;

	@include mediaMax($screenLg) {
		height: 840px;
		justify-content: center;
	}

	@include mediaMax($screenMd) {
		width: 339px;
		height: 744px;

		flex-direction: column;
		gap: 32px;

		padding-bottom: 20px;
	}
}

.title {
	width: 549px;

	color: white;
	font-size: 56px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 70px;
	letter-spacing: 2.8px;

	margin-bottom: 40px;

	@include mediaMax($screenMd) {
		width: 290px;
		height: 110px;

		font-size: 36px;
		line-height: 125%;
	}
}

.description {
	width: 559px;

	opacity: 0.8;

	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 500;
	line-height: 27px;

	span {
		font-weight: 800;

		cursor: pointer;
	}

	@include mediaMax($screenMd) {
		width: 289px;
		height: 187px;
	}
}

.portal {
	scale: 0.53;

	z-index: 10;

	height: 418px;
	width: 450px;

	margin-top: -170px;
	margin-right: 50px;

	opacity: 1;

	@include mediaMax($screenMd) {
		scale: 0.39;

		margin-top: -120px;

		position: relative;
		left: -94px;
	}
}

.mask {
	width: 1361px;
	max-width: 98vw;
	height: 384px;

	border-radius: 11px;

	position: absolute;
	top: 0;
	left: 0;

	overflow: hidden;

	pointer-events: none;

	z-index: 1;

	@include mediaMax($screenLg) {
		height: 840px;
	}

	@include mediaMax($screenMd) {
		width: 339px;
		height: 744px;
	}
}

.violetShadow {
	position: absolute;
	top: -500px;
	left: -450px;

	@include mediaMax($screenLg) {
		top: -200px;
	}

	@include mediaMax($screenMd) {
		top: -500px;
		left: -700px;
	}
}

.leftSide {
	margin-top: 44px;
	margin-left: 40px;

	z-index: 10;

	@include mediaMax($screenMd) {
		margin-top: 30px;
		margin-left: 24px;
	}
}

.purpleShadow {
	position: absolute;
	top: -140px;
	right: -500px;

	@include mediaMax($screenLg) {
		top: 0;
	}

	@include mediaMax($screenMd) {
		top: -140px;
		right: -500px;
	}
}

.pinkShadow {
	position: absolute;
	top: -500px;
	right: -300px;

	@include mediaMax($screenLg) {
		top: -300px;
	}
}
