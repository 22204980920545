//Home
$mainFontFamily: "PixudiGame-regular", sans-serif;
$mainFontFamilyExtrude: "PixudiGame-extrude", sans-serif;
$subMainFontFamily: "Montserrat", sans-serif;

$contetnFontSize: 18px;
$contetnFontSizeSmaller: 16px;
$contetnFontSizeSmallerInput: 12px;
$titleFontSize: 20px;
$titleBigFontSize: 36px;
$titleBlockFontSize: 32px;

$colorMainText: #ffffff;

@keyframes glitch {
	0% {
		transform: translate(0);
	}

	20% {
		transform: translate(-3px, 3px);
	}

	40% {
		transform: translate(-3px, -3px);
	}

	60% {
		transform: translate(3px, 3px);
	}

	80% {
		transform: translate(3px, -3px);
	}

	100% {
		transform: translate(0);
	}
}

@keyframes glitch2 {
	0% {
		filter: drop-shadow(0 0 20px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);
	}

	25% {
		filter: drop-shadow(0 0 10px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);
	}

	50% {
		filter: drop-shadow(0 0 0px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);
	}

	75% {
		filter: drop-shadow(0 0 10px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);
	}

	100% {
		filter: drop-shadow(0 0 20px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);
	}
}
