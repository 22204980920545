@import "/src/app/styles/index";

.content {
	display: grid;
	align-content: start;
	justify-items: center;
}

.title {
	text-align: center;
	color: white;
	font-size: 56px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 70px;
	letter-spacing: 2.8px;

	filter: drop-shadow(0 0 20px #6b40c4) drop-shadow(0 0 20px #4fd4f1);

	@include mediaMax($screenMd) {
		font-size: 36px;
	}
}

.areaBlocks {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 50px;

	margin-bottom: 36px;

	@include mediaMax($screenMd) {
		row-gap: 30px;

		flex-direction: column;

		margin-top: 30px;
	}
}

.block {
	width: 419px;
	height: 372px;

	background: url("../../assets/img/mainFeatures/block.svg");
	background-size: contain;

	display: grid;
	justify-items: center;
	align-content: start;

	margin-top: 77px;

	overflow: hidden;

	position: relative;

	&:hover {
		.shadow {
			display: block;
		}
	}

	@include mediaMax($screenMd) {
		width: 338px;
		height: 300px;

		background-size: cover;

		margin-top: 0;
	}
}

.iconBlock {
	margin-top: 22px;
	margin-bottom: 30px;

	@include mediaMax($screenMd) {
		margin-top: 14px;
		margin-bottom: 8px;
	}
}

.board {
	width: 57px;

	@include mediaMax($screenMd) {
		width: 46px;
	}
}

.diamond {
	width: 48px;

	@include mediaMax($screenMd) {
		width: 42px;
	}
}

.passes {
	width: 36px;

	@include mediaMax($screenMd) {
		width: 26px;
	}
}

.titlePasses,
.titleDiamond,
.titleBoard {
	text-align: center;
	color: white;
	font-size: 24px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 30px;
	letter-spacing: 1.2px;

	@include mediaMax($screenMd) {
		font-size: 16px;
	}
}

.diamond,
.titleDiamond {
	filter: drop-shadow(0 0 10px #55e8df)
		drop-shadow(0 0 2px rgba(85, 232, 223, 0.5));
}

.passes,
.titlePasses {
	filter: drop-shadow(0 0 10px #8f73ff)
		drop-shadow(0 0 2px rgba(143, 115, 255, 0.5));
}

.board,
.titleBoard {
	filter: drop-shadow(0 0 10px #6b40c4)
		drop-shadow(0 0 2px rgba(107, 64, 196, 0.5));
}

.description {
	opacity: 0.8;

	text-align: center;
	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 500;
	line-height: 22.4px;

	width: 300px;

	margin-top: 38px;

	@include mediaMax($screenMd) {
		margin-top: 10px;
		width: 242px;
	}
}

.shadow {
	display: none;

	position: absolute;

	mask: url("../../assets/img/mainFeatures/block.svg") no-repeat;

	@include mediaMax($screenMd) {
		width: 338px;
		height: 300px;

		mask-size: contain;
	}
}
