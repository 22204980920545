@import "/src/app/styles/index";

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	margin-top: 96px;
}

.title {
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	font-size: 56px;
	line-height: 125%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	text-align: center;
	color: #fff;

	margin-bottom: 50px;

	@include mediaMax($screenMd) {
		font-size: 36px;
	}
}

.subTitle {
	font-family: $subMainFontFamily;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #fff;
	opacity: 0.8;

	margin-bottom: 40px;
}

.wrap {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	position: relative;

	width: 100vw;
	max-width: 1440px;

	margin-top: -80px;
}

.muzzle {
	top: 82px;
	right: 0;
}

.radiator {
	bottom: 106px;
	right: 0;

	height: 290px !important;

	& > div:nth-child(2) {
		width: 388px;

		margin-left: 18px;
		margin-top: -10px;
	}
}

.reactor {
	bottom: 219px;
	left: 42px;

	height: 290px !important;

	& > div:nth-child(2) {
		width: 388px;

		margin-left: 13px;
		margin-top: -10px;
	}
}

.trigger {
	bottom: 12px;
	left: 72px;

	height: 290px !important;

	& > div:nth-child(2) {
		width: 388px;

		margin-left: 21px;
		margin-top: -10px;
	}
}

.fuelTank {
	bottom: 12px;
	left: 72px;

	height: 239px !important;

	& > div:nth-child(1) {
		margin-top: 20px;
	}

	& > div:nth-child(2) {
		width: 388px;

		margin-left: 21px;
		margin-top: -10px;
	}
}

.handle {
	bottom: 16px;
	left: 83px;

	height: 239px !important;

	& > div:nth-child(1) {
		margin-top: 20px;
	}

	& > div:nth-child(2) {
		width: 388px;

		margin-left: 21px;
		margin-top: -10px;
	}
}

.selectedElement {
	position: absolute;

	border-radius: 10px;
	background-image: url("../../assets/img/blasterBlock/backgroundMuzzle.svg");
	background-repeat: no-repeat;
	background-size: cover;

	width: 431px;
	height: 273px;

	@include mediaMax($screenAdd) {
		position: relative;
		top: auto;
		bottom: auto;
		left: auto;
		right: auto;
	}

	@include mediaMax($screenMoreSm){
		scale: 0.9;
	}
}

.selectedElementTitle {
	font-family: $mainFontFamily;
	font-weight: 400;
	font-size: 24px;
	line-height: 125%;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	text-align: center;

	margin-top: 27px;
	margin-bottom: 21px;
}

.selectedElementText {
	font-family: $subMainFontFamily;
	font-weight: 500;
	font-size: 16px;
	line-height: 140%;
	text-align: center;
	color: #fff;
	opacity: 0.8;

	width: 370px;

	padding-bottom: 32px;
	margin-left: 34px;
}

.blaster {
	width: 558px;
	height: 571px;

	position: relative;

	margin-bottom: 30px;

	@include mediaMax($screenSm) {
		transform: scale(0.7);
	}

	& > img {
		position: absolute;
		top: 0;
		left: 0;

		width: 558px;
		height: 571px;
	}

	& > div {
		cursor: pointer;
	}
}

.muzzleBlock {
	position: absolute;
	top: 150px;
	right: 55px;

	width: 104px;
	height: 118px;

	z-index: 3;
}

.radiatorBlock {
	position: absolute;
	top: 194px;
	right: 155px;

	width: 172px;
	height: 191px;

	z-index: 3;
}

.reactorBlock {
	position: absolute;
	top: 228px;
	left: 49px;

	width: 171px;
	height: 167px;

	z-index: 3;
}

.triggerBlock {
	position: absolute;
	top: 364px;
	left: 209px;

	width: 171px;
	height: 167px;

	z-index: 3;
}

.fuelTankBlock {
	position: absolute;
	top: 106px;
	left: 181px;

	width: 171px;
	height: 121px;

	z-index: 3;
}

.handleBlock {
	position: absolute;
	bottom: 25px;
	left: 101px;

	width: 95px;
	height: 111px;

	z-index: 3;
}

.active {
	z-index: 2;
}
