@import "/src/app/styles/index";

.wBtn {
	width: 153px;
	height: 44px;

	display: grid;
	justify-items: center;
	align-items: center;

	position: relative;
}

.btn {
	cursor: pointer;

	position: absolute;

	top: 0;
	left: 0;
}

.text {
	filter: drop-shadow(1px 0 0 #220059) drop-shadow(-1px 0 0 #220059)
		drop-shadow(0 1px 0 #220059) drop-shadow(0 -1px 0 #220059);

	color: white;

	text-align: center;
	font-size: 16px;
	font-family: $mainFontFamily;
	font-weight: 400;

	cursor: pointer;
}

.desc {
	color: white;
	text-align: center;
	font-size: 11px;
	font-family: $mainFontFamily;
	font-weight: 400;

	position: absolute;

	filter: drop-shadow(0 0 20px black) drop-shadow(0 0 20px black)
		drop-shadow(0 0 20px black) drop-shadow(0 0 20px black);
}
