@import "/src/app/styles/index";

.content {
	width: calc(100vw);

	overflow-x: hidden;

	@include mediaMax($screenMd) {
		width: 100vw;
	}
}
