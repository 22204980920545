@import "normalize";
@import "media";
@import "main";

body {
	margin: 0;
	/* Background: #000; */
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	/* overflow: hidden; */
	height: 100%;
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

:root {
	background: #09121b;
}

body {
	overflow-x: hidden;
}

@font-face {
	font-family: PixudiGame-regular;
	src: url("../../assets/fonts/PixudiGame-regular.ttf");
	font-style: normal;
}

@font-face {
	font-family: PixudiGame-extrude;
	src: url("../../assets/fonts/PixudiGame-Extrude.ttf");
	font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
