@import "/src/app/styles/index";

.content {
	margin-top: 150px;

	svg {
		width: calc(100vw);

		margin-bottom: 102px;
	}

	@include mediaMax($screenMd) {
		svg {
			width: 100vw;
		}
	}
}

.title {
	text-align: center;
	color: white;
	font-size: 24px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 30px;
	letter-spacing: 1.2px;

	filter: drop-shadow(0 0 16px #4fd4f1) drop-shadow(0 0 10px #6b40c4);

	margin-bottom: 56px;

	@include mediaMax($screenMd) {
		font-size: 20px;
	}

	&::before,
	&::after {
		content: attr(data-text);
		display: block;

		position: absolute;
		top: 0;
		left: 50%;
		translate: -50%;

		opacity: 0.8;

		width: 400px;
	}

	&::before {
		animation: glitch 2s both infinite;
		color: #6b40c4;

		z-index: -1;
	}

	&::after {
		animation: glitch 2s reverse both infinite;
		color: #ff7bb5;

		z-index: -2;
	}
}
