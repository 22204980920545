@import "/src/app/styles/index";

.content {
	margin-top: 50px;
}

.title {
	position: relative;

	text-align: center;
	color: #f7f0ff;
	font-size: 56px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 70px;
	letter-spacing: 2.8px;

	animation: glitch2 3s both infinite;

	z-index: 4;

	&::before,
	&::after {
		content: attr(data-text);
		display: block;

		position: absolute;
		top: 0;
		left: 50%;
		translate: -50%;

		opacity: 0.8;

		width: 1000px;

		@include mediaMax(814px) {
			width: 600px;
		}

		@include mediaMax(550px) {
			width: 390px;
		}
	}

	&::before {
		animation: glitch 2s both infinite;
		color: #6b40c4;

		z-index: -1;
	}

	&::after {
		animation: glitch 2s reverse both infinite;
		color: #ff7bb5;

		z-index: -2;
	}

	@include mediaMax($screenMd) {
		font-size: 36px;
		line-height: 125%;
	}
}

.building {
	width: 523px;
	height: 486px;

	@include mediaMax($screenMd) {
		width: 396px;
		height: 374px;
	}
}

.wrap {
	display: grid;
	grid-template-columns: 1fr 1.5fr 1fr;
	justify-content: space-between;
	justify-items: center;

	width: calc(100vw);

	margin-top: 10px;

	overflow: hidden;

	@include mediaMax($screenMd) {
		grid-template-columns: 1fr;

		width: 100vw;
	}
}

.rightMenu {
	justify-self: right;

	margin-top: 52px;

	position: relative;
	left: 20px;

	@include mediaMax($screenMd) {
		justify-self: center;

		left: 0;

		margin-top: 21px;
	}
}
