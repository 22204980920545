@import "/src/app/styles/index";

.content {
	margin-top: 100px;

	display: grid;
	justify-items: center;
	align-content: start;

	margin-bottom: 100px;

	@include mediaMax($screenMd) {
		margin-top: 70px;
	}
}

.title {
	width: 504px;

	text-align: center;
	color: white;
	font-size: 56px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 70px;
	letter-spacing: 2.8px;

	margin-bottom: 60px;

	filter: drop-shadow(0 0 20px #4fd4f1) drop-shadow(0 0 10px #4fd4f1);

	@include mediaMax($screenMd) {
		font-size: 36px;

		width: 350px;
	}
}

.areaBlocks {
	display: flex;
	flex-wrap: wrap;

	justify-content: center;

	row-gap: 25px;
	column-gap: 34px;

	max-width: 1440px;
	width: calc(100vw);
}

.block {
	background: url("../../assets/img/whatIsComing/block.svg") no-repeat;
	background-size: contain;

	width: 431px;
	height: 202px;

	display: grid;
	justify-items: center;
	align-content: start;

	@include mediaMax($screenMd) {
		width: 340px;
		height: 158px;
	}
}

.titleBlock {
	text-align: center;
	color: white;
	font-size: 24px;
	font-family: $mainFontFamilyExtrude;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 30px;
	letter-spacing: 1.2px;

	filter: drop-shadow(0 0 10px #6b40c4) drop-shadow(0 0 30px #6b40c4);

	margin-top: 40px;

	width: 337px;

	@include mediaMax($screenMd) {
		margin-top: 18px;

		font-size: 16px;

		width: 280px;
	}
}

.desc {
	opacity: 0.8;

	width: 374px;

	text-align: center;
	color: white;
	font-size: 16px;
	font-family: $subMainFontFamily;
	font-weight: 500;
	line-height: 22.4px;

	margin-top: 26px;

	@include mediaMax($screenMd) {
		margin-top: 10px;

		line-height: 20px;

		width: 293px;
	}
}

.descSpec {
	margin-top: 20px;

	@include mediaMax($screenMd) {
		margin-top: 10px;
	}
}

.titleBlockSpec {
	margin-top: 30px;
}
